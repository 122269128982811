import React, { useState, useMemo, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import * as action from '../../store/actions'

import { Row, Col, Card, CardBody, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import TableContainer from "../../components/Common/TableContainer"
import { format, parseISO } from 'date-fns';
import TransactionModal from "./TransactionModal" // Import the TransactionModal component
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function FinancialReport() {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalOpenDetails, setIsModalOpenDetails] = useState(false)
  const [isDateRangeModalOpen, setIsDateRangeModalOpen] = useState(false)

  const [selectedTransaction, setSelectedTransaction] = useState(null)
  const [selectedTransactionDetails, setSelectedTransactionDetails] = useState(null)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)

  const transactions = useSelector(state => state.transactions)
  const dispatch = useDispatch()

  const invoices = [];

  // Assuming transactions is an array of objects with a 'booking' property containing the payment status
  const PaidInvoices = transactions.filter((transaction) => transaction.paid_status === 'pending');

  // You can append the extracted transactions to the invoices array
  invoices.push(...PaidInvoices);



  useEffect(() => {
    dispatch(action.fetchTransactions())
  }, [dispatch])


  const handleRowClickDetails = transaction => {
    setSelectedTransactionDetails(transaction)
    toggleModalDetails()
  }

  const toggleModalDetails = () => {
    setIsModalOpenDetails(!isModalOpenDetails)
  }


  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)
  }

  const toggleDateRangeModal = () => {
    setIsDateRangeModalOpen(!isDateRangeModalOpen)
  }

  const handleDateChange = (dates) => {
    const [start, end] = dates
    setStartDate(start)
    setEndDate(end)
  }

  const applyDateFilter = () => {
    toggleDateRangeModal()
  }

  const filteredTransactions = transactions.filter((transaction) => {
    if (startDate && endDate) {
      const transactionDate = parseISO(transaction.$createdAt)
      return transactionDate >= startDate && transactionDate <= endDate
    }
    return true
  })

  const paidInvoices = filteredTransactions.filter((transaction) => transaction.paid_status === 'pending')

  const columns = useMemo(
    () => [
      {
        Header: "تاريخ العملية",
        accessor: "$createdAt",
        Cell: (cellProps) => {
          return <span>{format(parseISO(cellProps.value), 'MM dd, yyyy')}</span>;
        },
      },
      {
        Header: "مقدم الخدمة",
        accessor: "customerName",
        Cell: ({ row }) => row.original.serviceProviders && row.original.serviceProviders.salon_name,
      },
      {
        Header: "عرض التفاصيل",
        accessor: "action",
        disableFilters: true,
        Cell: ({ row }) => (
          <Button
            type="button"
            style={{ width: "100%" }}
            onClick={() => handleRowClickDetails(row.original)}
            className={"btn btn-primary text-center"}
          >
            عرض
          </Button>
        ),
      },
      {
        Header: "دفع المستحق",
        accessor: "paid_status",
        disableFilters: true,
        Cell: ({ row }) => (
          <Button
            type="button"
            style={{ width: "100%" }}
            onClick={() => handleRowClick(row.original)}
            className={"btn btn-warning text-center"}
          >
            دفع
          </Button>
        ),
      },
      {
        Header: (
          <Button
            className={"btn btn-primary text-center"}
            onClick={toggleDateRangeModal}
          >
            حدد نطاق التاريخ
          </Button>
        ),
        accessor: "button",
        disableFilters: true,
      },
    ],
    []
  )

  return (
    <React.Fragment>
      <Row>
        <Col lg="12">
          {/* Your Financial Report Content */}
          <Card>
            <CardBody>
              {/* Your Financial Report Table */}
              <h2>مستحقات منتظرة </h2>

              <TableContainer
                columns={columns}
                data={paidInvoices}
                isGlobalFilter={true}
                isAddOptions={false}
                customPageSize={10}
                className="custom-header-css"
              />

              <TransactionModal
                isOpen={isModalOpenDetails}
                toggleModal={toggleModalDetails}
                transaction={selectedTransactionDetails}
              />
              <Modal isOpen={isDateRangeModalOpen} toggle={toggleDateRangeModal}>
                <ModalHeader toggle={toggleDateRangeModal}>حدد نطاق التاريخ</ModalHeader>
                <ModalBody style={{ display: 'flex', justifyContent: 'center' }}>
                  <DatePicker
                    selected={startDate}
                    onChange={handleDateChange}
                    startDate={startDate}
                    endDate={endDate}
                    selectsRange
                    inline
                  />
                </ModalBody>
                <ModalFooter>
                  <Button color="primary" onClick={applyDateFilter}>
                    تطبيق
                  </Button>
                  <Button color="secondary" onClick={toggleDateRangeModal}>
                    إلغاء
                  </Button>
                </ModalFooter>
              </Modal>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default FinancialReport