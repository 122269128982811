import AWS from 'aws-sdk';

/**
 * Digital Ocean Spaces Connection
 */

const spacesEndpoint = new AWS.Endpoint("blr1.digitaloceanspaces.com");
export { spacesEndpoint };

const s3 = new AWS.S3({
      endpoint: spacesEndpoint,
      accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_ACCESS_SECRET_KEY,
    });
export default s3;