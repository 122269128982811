import { takeLatest, put, all, call, takeEvery } from "redux-saga/effects"
import * as actionTypes from "./actionTypes"
import * as actions from "./actions"
import { databases, storage } from "../../helpers/appwrite/appwriteConfig" // Import your appwrite instance
import { ID, Query } from "appwrite"
import appwritPointes from "helpers/appwrite/appwritePointes"
import Cookies from "js-cookie"
import s3 from "helpers/digital/DigitalOcean"
import digitalPoints from "helpers/digital/digitalPoints"

// Worker Saga for adding a new service
async function addService(newService) {
  try {
    const img = newService.img_file

    // Assuming img is a file path or a readable stream
    const uploadParams = {
        Bucket: digitalPoints.butketName, // Your Space name
        Key: `services/${Date.now()}-${img.name}`, // Unique file name
        Body: img, // If img is a readable stream or buffer
        ACL: "public-read" // Set ACL as needed
    };

    const imgUploaded = await s3.upload(uploadParams).promise();
    const imgUrl = imgUploaded.Location; // Get the URL of the uploaded image


    const updatedFormData = {
      service_provider_auth_id: newService.provider_id,
      service_provider_id: newService.provider_id,
      name: newService.name,
      service_price: newService.service_price,
      img_url: imgUrl,
      img_id: imgUploaded.Key,
      description: newService.description,
      service_type: newService.service_type,
      service_category: newService.category_type,
      avg_execute_time: newService.avg_execute_time,
      at_home_service_cost: newService.at_home_service_price,
      serviceProviders: `${newService.provider_id}`,
    }
    const response = await createServiceDocument(updatedFormData)
    return response
  } catch (error) {
    throw error
  }
}
function* addServiceSaga(action) {
  try {
    const newService = action.payload
    const response = yield call(addService, newService)
    yield put(actions.addServiceSuccess(response))
  } catch (error) {
    throw error
  }
}
async function uploadImage(img) {
  try {
    return await storage.createFile(appwritPointes.bucketID, ID.unique(), img)
  } catch (error) {
    throw error
  }
}
async function createServiceDocument(formData) {
  try {
    return await databases.createDocument(
      appwritPointes.databaseID,
      appwritPointes.servicesID,
      ID.unique(),
      formData
    )
  } catch (error) {
    throw error
  }
}
// Worker Saga for updating a service
async function updateServiceData(id, updatedService) {
  const updatedFormData = {
    name: updatedService.name, // Use updatedService properties directly
    service_price: updatedService.service_price,
    description: updatedService.description,
    service_type: updatedService.service_type,
    service_category: updatedService.service_category,
    avg_execute_time: updatedService.avgExecuteTime,
    at_home_service_cost: updatedService.atHomeServiceCost,
  };
  try {
    const response = await databases.updateDocument(
      appwritPointes.databaseID,
      appwritPointes.servicesID,
      id,
      updatedFormData
    );
    return response;
  } catch (error) {
    throw error;
  }
}

function* updateServiceSaga(action) {
  try {
    const updatedService = action.payload
    const response = yield call(
      updateServiceData,
      updatedService.id,
      updatedService
    )
    yield put(actions.updateServiceSuccess(response))
  } catch (error) {
    throw error
  }
}
// Worker Saga for removing a service
async function removeService(id) {
  try {
    const result = await databases.getDocument(
      appwritPointes.databaseID,
      appwritPointes.servicesID,
      id
    )

    if (result.img_id) {
        const deleteParams = {
          Bucket: digitalPoints.butketName, // Your Space name
          Key: result.img_id, // Unique file name       // File name to delete
        };
      await s3.deleteObject(deleteParams).promise();
    }
    const response = await databases.deleteDocument(
      appwritPointes.databaseID,
      appwritPointes.servicesID,
      id
    )
    return response
  } catch (error) {
    throw error
  }
}
function* removeServiceSaga(action) {
  try {
    const serviceId = action.payload
    // Call the Appwrite SDK function to delete the document from your service collection
    const response = yield call(removeService, serviceId)
    // Dispatch success action
    yield put(actions.removeServiceSuccess(serviceId))
  } catch (error) {
    // Dispatch failure action
    throw error
  }
}
async function fetchService() {
  // Correct the function name
  const provider_id = Cookies.get("usersessionId", {
    secure: true,
    sameSite: "strict",
    path: "/",
  })
  try {
    const response = await databases.listDocuments(
      appwritPointes.databaseID, // Replace with your collection ID
      appwritPointes.servicesID, // Replace with your document ID
      [Query.equal("service_provider_auth_id", [provider_id])]
    )
    return response
  } catch (error) {
    throw error
  }
}
function* fetchServicesSaga() {
  try {
    const response = yield call(fetchService)
    const services = response.documents
    yield put(actions.fetchServicesSuccess(services))
  } catch (error) {
    yield put(actions.fetchServicesFailure(error.message))
    throw error
  }
}
async function fetchCategorise() {
  // Correct the function name
  try {
    const response = await databases.listDocuments(
      appwritPointes.databaseID, // db ID
      appwritPointes.categoriseID //  collection ID
    )
    return response.documents
  } catch (error) {
    throw error
  }
}
function* fetchCategoriseSaga() {
  try {
    const response = yield call(fetchCategorise)
    yield put(actions.fetchCategoriesSuccess(response))
  } catch (error) {
    throw error
    // yield put(actions.fetchServicesFailure(error.message))
  }
}
async function fetchTypes() {
  // Correct the function name
  try {
    const response = await databases.listDocuments(
      appwritPointes.databaseID, // db ID
      appwritPointes.types //  collection ID
    )
    return response.documents
  } catch (error) {
    throw error
  }
}
function* fetchTypesSaga() {
  try {
    const response = yield call(fetchTypes)
    yield put(actions.fetchTypesSuccess(response))
  } catch (error) {
    throw error
  }
}
// Watcher Saga
function* watchServiceSaga() {
  yield all([
    takeLatest(actionTypes.ADD_SERVICE, addServiceSaga),
    takeLatest(actionTypes.UPDATE_SERVICE, updateServiceSaga),
    takeLatest(actionTypes.REMOVE_SERVICE, removeServiceSaga),
    takeEvery(actionTypes.FETCH_SERVICES_REQUEST, fetchServicesSaga),
    takeLatest(actionTypes.FETCH_CATEGORIES_REQUEST, fetchCategoriseSaga),
    takeLatest(actionTypes.FETCH_TYPES_REQUEST, fetchTypesSaga),
    // Add other sagas if needed
  ])
}

export default watchServiceSaga
